import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b86225e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardGrid = _resolveComponent("DashboardGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashboardGrid, {
      url: '/form/grid/DashboardOrdersCountSummaryPartial',
      additionalData: _ctx.dashboardOrderPrintTypeGeneral,
      dataFunctionName: 'orderPrintTypeGeneral',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }, null, 8, ["additionalData"]),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardOrdersValuePartial',
      exportUrl: 'form/grid/ExportOrdersCsv',
      exportName: 'Orders'
    }),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardRedoOrdersPercentagePartial',
      exportUrl: 'form/grid/ExportRedoOrdersCsv',
      exportName: 'RedoOrders'
    }),
    _createVNode(_component_DashboardGrid, {
      url: 'form/grid/DashboardRefundOrdersPercentagePartial',
      exportUrl: 'form/grid/ExportRefundsCsv',
      exportName: 'RefundOrders'
    })
  ]))
}